import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

// Import Background Image
import Img1 from "../assets/images/about/img-1.png";
import Img2 from "../assets/images/about/img-2.png";
//import icon
import FeatherIcon from "feather-icons-react";


function About() {

  return (
    <React.Fragment>
      <section className="section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="text-center mb-5">
                <h2 className="">About Us</h2>
                <p className="text-muted">
                  Welcome to Laxmi Avenue, a premier gated community nestled in the serene neighborhood of Haridaspur, Naharkanta, Bhubaneswar. We take immense pride in providing a secure and harmonious living environment for our esteemed residents.
                </p>
                <p className="text-muted">
                  At Laxmi Avenue, we understand the significance of finding a home that reflects your aspirations and values. Our community is meticulously designed to offer a perfect blend of modern amenities, lush green spaces, and a strong sense of belonging.
                </p>
                <p className="text-muted">
                  Located at Laxmi Avenue, our community boasts a prime location, providing convenient access to the city's key landmarks, educational institutions, healthcare facilities, and entertainment hubs. Whether you seek a peaceful retreat or a vibrant social life, Laxmi Avenue caters to all your needs.
                </p>
                <p className="text-muted">
                  Our gated community features a range of thoughtfully crafted residences, including luxurious apartments, spacious villas, and well-designed townhouses. Each home is crafted with precision, focusing on functionality, comfort, and aesthetic appeal. We prioritize quality construction, ensuring that every resident enjoys a durable and beautiful abode.
                </p>
                <p className="text-muted">
                  Living at Laxmi Avenue offers you an array of world-class amenities that enhance your lifestyle. Take a refreshing dip in the sparkling swimming pool, maintain your fitness goals at the well-equipped gymnasium, or enjoy a leisurely stroll in the landscaped gardens. We also provide dedicated spaces for community events, celebrations, and recreational activities, fostering a strong sense of camaraderie among the residents.
                </p>
                <p className="text-muted">
                  Safety and security are of paramount importance at Laxmi Avenue. Our gated community is equipped with round-the-clock security personnel and advanced surveillance systems to ensure the well-being of our residents. You can rest assured knowing that your loved ones and belongings are protected within our premises.
                </p>
                <p className="text-muted">
                  We believe in fostering a vibrant and inclusive community spirit. Laxmi Avenue organizes various social and cultural events throughout the year, providing opportunities for residents to connect, collaborate, and celebrate together. We encourage a healthy and harmonious living environment, promoting mutual respect, tolerance, and cooperation.
                </p>
                <p className="text-muted">
                  Explore Laxmi Avenue and experience the joys of contemporary living in a tranquil setting. Join our thriving community and create lasting memories with your loved ones. We are committed to exceeding your expectations and providing you with a fulfilling lifestyle that you truly deserve.
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </React.Fragment>
  );

}

export default About;