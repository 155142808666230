import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";
import axios from "axios";

import Background from "../../assets/images/service-icon-bg.png";

import { apiUrl } from "../../utils/defaultValues";

function Notices() {


    const [notice, setNotice] = useState([]);


    const getAllNotices = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: `${apiUrl}notifications/NoticepageRouter/getAll`
            });
            if (res.status === 200) {
                setNotice(res.data.data.filter((e) => e.type == 'public'));
            }
            console.log(res);

        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        getAllNotices()
    }, [])


    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        rows: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerPadding: '20px'
                }
            }
        ]
    };

    const slides = notice?.map((item, key) => {
        return (
            <div className="item" key={key}>
                <div className="card service-box flex-md-column flex-lg-row text-center p-4" style={{ height: '350px', width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="service-icon-bg mx-auto avatar-xxl p-4" style={{ backgroundImage: `url(${Background})` }}>
                        <div className="service-box-icon">
                            <img src={item.Profile} alt="" className="img-fluid d-block" />
                        </div>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <h6 className="service-title f-16">{item.Notice}</h6>
                        <p className="service-subtitle mb-1 f-16">{item.Description}</p>
                        <h6 className="service-title mt-4 mb-2 f-14" style={{ display: 'flex', justifyContent: 'flex-end' }}>{item.Name}</h6>
                        <p className="service-title" style={{ fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>{item.Date}</p>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <React.Fragment>
            <section className="section" id="clients">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="text-center mb-5">
                                <h2 className="">Notices</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div
                                id="testi-clients"
                                className="owl-carousel owl-theme testi-content"
                            >
                                <Slider {...settings}>
                                    {slides}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Notices;