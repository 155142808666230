import Layout1 from "../src/pages/Layout1/Layout1";
import Home from "../src/pages/Home/Home";
import Layout3 from "../src/pages/Layout3/Layout3";
import Layout4 from "../src/pages/Layout4/Layout4";
import Layout5 from "../src/pages/Layout5/Layout5";
import Notices from "../src/pages/Notices/Notices";
import Gallery from "../src/pages/Gallery/Gallery";
import Team from "../src/pages/Team/Team";
import About from "../src/component/About";
import CotactUs from "../src/component/Contact";
import Feedback from "./pages/Feedback/Feedback";
import Helpline from "./pages/Helpline/Helpline";

const routes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/notices", component: Notices },
  { path: "/gallery", component: Gallery },
  { path: "/team", component: Team },
  { path: "/about", component: About},
  { path: "/contact", component: CotactUs},
  { path: "/feedback", component: Feedback},
  { path: "/helpline", component: Helpline},

  { path: "/Layout4", component: Layout4 },
  { path: "/Layout5", component: Layout5 },
];

export default routes;
