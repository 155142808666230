import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Row } from "reactstrap";
import { callApi } from "../../api_handler/callApi";

function Feedback() {

    const [feedback, setFeedback] = useState([]);

    const [showFullDescription, setFullDescription] = useState(false);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [seeMore, setSeeMore] = useState(false);


    const addFeedback = async (e) => {
        try {
            const config = {
                method: 'post',
                url: 'notifications/FeedbackRouter/Addnew',
                data: {
                    Message: message,
                    Name: name,
                    Email: email,
                    Date: new Date().toDateString()
                }
            }
            let response = await callApi(config);
            if (response.data.code === 201) {
                await getAllFeedbacks()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllFeedbacks = async (e) => {
        try {
            const userData = {
                method: 'post',
                url: 'notifications/FeedbackRouter/getAllFeedback'
            }
            let res = await callApi(userData);

            if (res.data.code === 200) {
                setFeedback(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllFeedbacks()
    }, [])

    useEffect(() => {
        console.log({ feedback })
    }, [feedback])


    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const description = (index) => {
        console.log({ first: feedback[index].Message })
        return showFullDescription
            ? feedback[index]?.Message
            : feedback[index]?.Message?.slice(0, 90);
    }
    const MessageItem = ({ message }) => {
        const [showFullMessage, setShowFullMessage] = useState(false);

        const toggleMessage = () => {
            setShowFullMessage(!showFullMessage);
        };

        useEffect(() => {
            console.log({ second: message })
        }, [])
        return (
            <div>
                {showFullMessage ? (
                    <p>{message}</p>
                ) : (
                    <p>{message?.slice(0, 100)}...</p>
                )}
                <Badge backgroundColor="#fff" onClick={toggleMessage}>
                    Read {showFullMessage ? "Less" : "More"}
                </Badge>

            </div>
        );
    };
    return (
        <React.Fragment>
            <section className="section" id="contact" style={{ backgroundColor: '#000' }} >
                <Container>
                    <h2 style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginBottom: '30px', color: '#fff' }}>Feedback</h2>
                    <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className=" col-6 col-lg-4" style={{ backgroundColor: '#343a40', borderRadius: '10px', height: '280px', marginBottom: '30px' }}>
                            <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', margin: '10px' }}>

                                <label style={{ color: 'white', marginBottom: '0', fontSize: '14px' }}>Message</label>
                                <textarea rows={4} style={{ borderRadius: "10px", width: '100%', backgroundColor: '#000', color: '#fff', fontSize: '12px' }} onChange={(e) => setMessage(e.target.value)} />

                                <label style={{ color: 'white', marginBottom: '0', marginTop: '10px', fontSize: '14px' }} for='name' >Name</label>
                                <input type="text" name='name' style={{ borderRadius: "10px", width: '100%', backgroundColor: '#000', color: '#fff', fontSize: '12px' }} onChange={(e) => setName(e.target.value)} />

                                <label style={{ color: 'white', marginBottom: '0', marginTop: '10px', fontSize: '14px' }}>Email</label>
                                <input type="text" style={{ borderRadius: "10px", width: '100%', backgroundColor: '#000', color: '#fff', fontSize: '12px' }} onChange={(e) => setEmail(e.target.value)} />

                                <label style={{ display: 'none', color: 'white', marginBottom: '0', marginTop: '10px', fontSize: '14px' }}>Date</label>
                                <input type="Date" style={{ display: 'none', borderRadius: "10px", width: '100%', backgroundColor: '#000', color: '#fff', fontSize: '12px' }} onChange={(e) => setDate(e.target.value)} />

                                <Button color="primary" size="xs" style={{ borderRadius: "10px", margin: '10px' }} onClick={() => { addFeedback() }}>Post</Button>
                            </Form>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {
                                seeMore ?
                                    feedback?.map((item, index) => {
                                        return (
                                            <div key={index + item.Name}>
                                                <Card style={{ height: '100%', border: '2px solid #ffc107', borderRadius:'10px', backgroundColor: '#343a40', marginBottom: '5px' }} >
                                                    <p style={{ paddingInline: '15px', paddingTop: '5px', marginBottom: '2px', fontSize: '11px', fontWeight: '900', color: 'blue' }}>{item.Name} <span style={{ color: 'yellow', fontSize: '8px' }}>{item.Date}</span></p>
                                                    <div>
                                                        <div style={{ paddingInline: '15px', fontSize: '10px', marginBottom: '1px', color: '#fff' }}>
                                                            <MessageItem key={index} message={item.Message} />
                                                        </div>
                                                    </div>
                                                </Card>
                                                {
                                                    (item.Remark === "" || item.Remark === undefined) ?
                                                        <p style={{ paddingInline: '15px', paddingTop: '5px', marginBottom: '30px', fontSize: '10px', fontWeight: '900', color: '#fff' }}>Waiting for Response..</p>
                                                        :
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <Card style={{ height: '100%', width: '90%', border: '2px solid #fff', borderRadius:'10px', backgroundColor: '#343a40' }}>
                                                                <div style={{ paddingInline: '15px', fontSize: '10px', marginBottom: '1px', color: '#fff' }}>
                                                                    <p>{item.Remark}</p>
                                                                    <p style={{  paddingTop: '5px', marginBottom: '2px', fontSize: '10px', fontWeight: '900', color: 'blue' }}>Laxmi Avenue HouseOwner <span style={{ color: 'yellow', fontSize: '8px' }}>{item.RemarkDate}</span></p>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })
                                    :
                                    feedback.slice(0, 3).map((item, index) => {
                                        return (
                                            <div key={index + item.Name}>
                                                <Card style={{ height: '100px', border: '2px solid #ffc107', backgroundColor: '#343a40' }} >
                                                    <p style={{ paddingInline: '15px', paddingTop: '5px', marginBottom: '2px', fontSize: '10px', fontWeight: '900', color: 'blue' }}>{item.Name} <span style={{ color: 'yellow', fontSize: '8px' }}>{item.Date}</span></p>
                                                    <div>
                                                        <div style={{ paddingInline: '15px', fontSize: '10px', marginBottom: '1px', color: '#fff' }}>
                                                            <MessageItem key={index} message={item.Message} />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>

                                        )
                                    })
                            }
                            <button type="button" class="btn btn-primary btn-sm" style={{ width: '100px' }} onClick={() => setSeeMore(!seeMore)}>
                                {
                                    seeMore ? 'See Less' : 'See More'
                                }
                            </button>
                        </div>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Feedback;