
import { apiUrl } from '../utils/defaultValues';
import axios from 'axios';
export const callApi = async (apiCallData) => {
  var data = { ...apiCallData?.data, "api_key": `registeruser` }
  // const token = await getToken();
  let config = {
    method: apiCallData.method,
    url: `${apiUrl}${apiCallData.url}`,
    // headers: {
    //   'Authorization': `Bearer ${token}`,
    //   "Access-Control-Allow-Origin": "*"
    // },
    data: data
  }
  let response = await axios(config);
  return response;
  
}
