import React from "react";
import { Card, Container } from "reactstrap";
import FeatherIcon from "feather-icons-react";

function Helpline() {
    return (
        <React.Fragment>
            <section className="section" style={{ backgroundColor: '#40e0d0' }}>
                <Container>
                    <h1>Helpline</h1>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '50px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Local Police Station -<span style={{ fontWeight: '900', color: '#0473f2' }}>100</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Fire Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>101</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Ambulance Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>108</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Janani Ambulance Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>102</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Child Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>1098</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Women Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>1091</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Elder Person Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>1090</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    Trafic Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>1095</span>
                                </div>
                            </Card>
                            <Card style={{ display: 'flex', flexDirection: 'row', fontWeight: '700', marginBottom: '50px', height: '60px', width: '280px', marginInline: '20px', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div>
                                    <FeatherIcon className="icon-size-15 mr-1 icon" icon="phone" />
                                </div>
                                <div>
                                    N.H.A.I. Helpline -<span style={{ fontWeight: '900', color: '#0473f2' }}>1033</span>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Helpline;