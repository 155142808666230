import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Col,
  Container,
  Row
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import { Link } from "react-router-dom";
import { apiUrl } from "../../utils/defaultValues";

function Gallery() {

  const [gallery, setGallery] = useState([]);

  const [seeMore, setSeeMore] = useState(false);


  const getAllImages = async () => {
    try {
      const res = await axios({
        method: 'post',
        url: `${apiUrl}notifications/ImageRouter/getAllimage`
      });
      if (res.status === 200) {
        setGallery(res.data);
      }
      console.log(res);

    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    getAllImages()
  }, [])

  return (
    <React.Fragment>
      <section className="section bg-light" id="project">
        <Container>
          <Row className="justify-content-center">
            <div className="col-lg-7">
              <div className="text-center mb-5">
                <h2 className="">Our Gallery</h2>
              </div>
            </div>
          </Row>
          <Row>
            {/* Render Footer Link */}
            {
              seeMore ?
                gallery?.map((item, key) => {
                  return (
                    <Col lg={4} md={6} key={key}>
                      <div className="project-box mb-4">
                        <div className="position-relative overflow-hidden rounded">
                          <img src={item.Profile} alt="" style={{ height: '250px', width: 'auto' }} className="profile-fluid d-block mx-auto shadow" />
                          <div className="project-overlay">
                            <div className="project-content">
                              <p className="text-light">{item.Description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
                :
                gallery.slice(0, 6).map((item, key) => {
                  return (
                    <Col lg={4} md={6} key={key}>
                      <div className="project-box mb-4">
                        <div className="position-relative overflow-hidden rounded">
                          <img src={item.Profile} alt="" style={{ height: '250px', width: 'auto' }} className="profile-fluid d-block mx-auto shadow" />
                          <div className="project-overlay">
                            <div className="project-content">
                              <p className="text-light">{item.Description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
            }
          </Row>

          <button type="button" class="btn btn-primary btn-sm" onClick={() => setSeeMore(!seeMore)}>
            {
              seeMore ? 'See Less' : 'See More'
            }
          </button>

        </Container>
      </section>
    </React.Fragment>
  );
}

export default Gallery;