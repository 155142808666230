import React, { Component } from "react";
import { Link } from "react-router-dom";

//import icon
import FeatherIcon from "feather-icons-react";
import { Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";


// Import Background Image
// import ContactImg from "../assets/images/contact-img.png";
import footer_image from "../assets/images/hero-1-bg-img.png";


export default class Contact extends Component {
  render() {
    return (
      <React.Fragment>  
        <section className="section" id="contact" style={{ backgroundImage: "url(" + footer_image + ")" }} >
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="text-center mb-5">
                  <h2 className="">Contact Us</h2>
                  <p className="text-muted">For any inquiries or to schedule a visit, please contact us at:</p>
                  <p className="mt-4">Laxmi Avenue, Haridaspur, Naharkanta, Bhubaneswar-752101</p>
                  <Row>
                    <Col md={4}>
                      <Link to="#">
                        <div className="badge f-14 bg-soft-dark text-muted"><span className="text-dark mr-1">Email:</span> laxmiavenuebbsr@gmail.com</div>
                      </Link>
                    </Col>
                    <Col md={4} className="mt-2 mt-sm-0">
                      <Link to="#">
                        <div className="badge f-14 bg-soft-dark text-muted"><span className="text-dark mr-1">Phone:</span> +919337701995  </div>
                      </Link>
                    </Col>
                    <Col md={4} className="mt-2 mt-sm-0">
                      <Link to="#">
                        <div className="badge f-14 bg-soft-dark text-muted"><span className="text-dark mr-1">Phone:</span> +919777970044  </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

          </Container>
        </section>
      </React.Fragment>
    );
  }
}
