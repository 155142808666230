import React, { Component, Suspense } from "react";
import routes from "../src/routes";

import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

//import style
import "./assets/css/pe-icon-7.css";

import "./assets/scss/themes.scss";
const Navbar = React.lazy(() => import("./component/Navbar/NavBar"));

class App extends Component {
  constructor(props) {
    super(props);
    // this.state = {};
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "notices", navheading: "Notices" },
        { id: 3, idnm: "gallery", navheading: "Gallery" },
        { id: 4, idnm: "team", navheading: "Governing Body Member" },
        { id: 5, idnm: "about", navheading: "About Us" },
        { id: 7, idnm: "contact", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />
          <Router>
            <Switch>
              {routes.map((route, idx) => (
                <Route exact path={route.path} component={route.component} key={idx} />
              ))}
            </Switch>
          </Router>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
