import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";
import axios from "axios";

import { apiUrl } from "../../utils/defaultValues";

function Team() {

    const [team, setTeam] = useState([]);

    const getAllTeam = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: `${apiUrl}notifications/TeamNoticeRouter/AllTeamImage`
            });
            if (res.status === 200) {
                setTeam(res.data.data);
            }

        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        getAllTeam()
    }, [])




    return (
        <React.Fragment>
            <section className="section" id="clients">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="text-center mb-5">
                                <h2 className="">SOCIETY PERSONALS</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{justifyContent:'space-evenly'}} sm={12} md={4}>
                        {team?.map((item, key) =>
                            <div className="item" key={key}>
                                <div className="testi-box text-center m-2">
                                    <div className="test-user-info">
                                        <div className="card border-0 shadow p-4 mb-4">
                                            <div className="avatar-md mx-auto">
                                                <img src={item.Profile} alt="" className="img-fluid d-block rounded-circle testi-user-img" />
                                            </div>
                                            <h5 className="f-17 mt-3 mb-1">{item.Name}</h5>
                                            <p className="text-muted f-14">{item.position}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Team;